import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HoldTable from './HoldTable';
import orderHelpers from '../../helpers/orders';
import HoldOrderDetails from './HoldOrderDetails';
import CancelItemPopup from './CancelItemPopup';

function HoldOrders(props) {
    
    const [refresh, setRefresh] = useState(false);
    const [ordersRaw, setOrdersRaw] = useState([]);
    const [orders, setOrders] = useState([]);
    const [unacknowledgedOrders, setUnacknowledgedOrders] = useState([]);
    const [selected, setSelected] = useState([]);
    const [displayOrder, setDisplayOrder] = useState({});
    const [cancelItemOpen, setCancelItemOpen] = useState(false);
    const setLoading = props.setLoading;
    const company = props.company;
    const token = props.token;

    
    const handleSelect = (e) => {
        setSelected(e)
        let row = orders.filter(obj => obj.id === e[0])[0]
        let order = ordersRaw.filter(obj => obj.internal_po_number === row.internal_po_number);
        let skus = [...new Set(order.map(obj => obj.internal_sku))];
        let items = [];
        let id = 1;
        for(let sku of skus) {
            let rows = order.filter(obj => obj.internal_sku === sku);
            let qty = rows.reduce((a, b) => a + b.quantity, 0);
            let price = rows.reduce((a, b) => a + b.external_price, 0);
            items.push({
                internal_sku: sku,
                quantity: qty,
                external_price: price.toFixed(2),
                unit_price: rows[0].external_price,
                id: id
            })
            id++;
        }
        order[0].items = items;
        order[0].price = row.total_price;
        order[0].pieces = row.pieces
        setDisplayOrder(order[0])
        console.log(order[0])
    }

    const handleClose = (e) => {
        setDisplayOrder({})
    }

    const refreshOrders = async() => {
        setSelected([]);
        setDisplayOrder({});
        setOrdersRaw([]);
        setOrders([]);
        setUnacknowledgedOrders([]);
        setRefresh(!refresh);
    }

    useEffect(() => {
        const getOrders = async() => {
            setLoading(true);
            let new_orders = await orderHelpers.getHoldOrders(company, token);
            if(!props.scopes.includes("washington")) {
                console.log("Not HQ");
                new_orders = new_orders.filter(obj => obj.order_status === 197)
            }
            console.log(new_orders);
            setOrdersRaw(new_orders);
            let out = [];
            let pos = [...new Set(new_orders.map(obj => obj.internal_po_number))];
            let id = 1;
            for(let po of pos) {
                let orders = new_orders.filter(obj => obj.internal_po_number === po);
                let qty = orders.reduce((a,b)=>a + b.quantity, 0);
                let price = orders.reduce((a,b)=>a + b.external_price, 0.0);
                let order_status = orders.filter(obj => obj.order_status !== 199)[0]?.order_status
                out.push({
                    internal_po_number: po,
                    order_date: orders[0]?.order_date,
                    total_price: price.toFixed(2),
                    pieces: qty,
                    vendor_name: orders[0]?.vendor_name,
                    vendor_params: orders[0]?.vendor_params,
                    state: orders[0]?.state,
                    order_status: order_status,
                    id: id
                })
                id++;
            }
            setOrders(out);
            setLoading(false);
        }
    getOrders();
    }, [refresh, company, token, setLoading]);

    useEffect(() => {
        getUnacknowledgedOrders();
    }, [ordersRaw]);

    const getUnacknowledgedOrders = () => {
        const unackOrders = [];
        for (let order of ordersRaw) {
            if (order.order_status === 198) {
                unackOrders.push(order);
            }
            else continue;
        }
        setUnacknowledgedOrders(unackOrders);
    };

    const formatDate = (date) => {
        let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        year = year.toString().substring(2);

    return [month, day, year].join('/');
    };

    const exportUnacknowledgedOrders = async () => {
        const today = new Date().toISOString().slice(0, 10);
        const data = [["order_date", "requested_on" , "location", "internal_po_number", "internal_sku", "vendor_name", "external_sku", "quantity", "pa_inventory", "internal_price", "external_price", "city", "state"].join(",")];
        for (let order of unacknowledgedOrders) {
            const order_date = formatDate(order.order_date);
            const vendor_params = JSON.parse(order.vendor_params || "{}");
            const req_date = vendor_params.requested_on || "";
            const req_on = `${req_date.substring(4, 6)}/${req_date.substring(6, 9)}/${req_date.substring(0, 4)}`
            data.push(
                [order_date, req_on, order.name, order.internal_po_number, order.internal_sku, order.vendor_name, order.external_sku, order.quantity, order.pa_inventory, order.internal_price, order.external_price, order.city, order.state].join(",")
            );
        };
        const csv = data.join("\n");
        const uri = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
        const a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", today + " UnacknowledgedOrders.csv");
        document.body.appendChild(a);
        a.click();
    };

    return (
        <Box className = {props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
            <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{paddingLeft: 2}}>
                <h1>Orders On Hold</h1> 
                <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} display = "flex" flexDirection = "column">
                    <CancelItemPopup {...props} open = {cancelItemOpen} setOpen = {setCancelItemOpen} />
                    <Box>
                        <Button onClick = {() => setCancelItemOpen(true)} variant = "contained" color = "warning" sx = {{mr: 2}}>Cancel Items</Button>
                        <Button variant = "contained" color = "success" onClick = {() => setRefresh(!refresh)} sx = {{mr: 2}}>Refresh</Button>
                        <Button variant = "contained" className = {`${props.company} ${props.warehouse} tertiary`} onClick = {() => exportUnacknowledgedOrders()} sx = {{mr: 2}}>Export Unacknowledged Orders</Button>
                    </Box>

                    {displayOrder ? 
                        <HoldOrderDetails {...props} refresh={refreshOrders} order={displayOrder} setOpenDetails={handleClose} /> 
                    : null}
                    
                    <HoldTable 
                        isMobile = {props.isMobile}
                        orders = {orders}
                        handleSelect = {handleSelect}
                        selected = {selected}
                    />
                </Box>                
            </Box>
        </Box>
    );
};

export default HoldOrders;