import {React, useState, useEffect}from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DetailsItems from './DetailsItems';
import Alert from '@mui/material/Alert';
import orderHelpers from '../../helpers/orders';
import fileHelpers from '../../helpers/files';
import Grid from '@mui/material/Grid';

function DetailsPackage(props) {

    const shipment = props.shipment || {items: []};
    
    const [status, setStatus] = useState({});
    const [tStatus, setTStatus] = useState({});
    const [reshipSuccess, setReshipSuccess] = useState("");

    useEffect(() => {
        const daysInMs = 1 * 24 * 60 * 60 * 1000;
        const cutOff = new Date().getTime() - daysInMs;
        const date = new Date(shipment.date).getTime();
        setStatus(shipment.acumatica_status === "acumatica_processing" ? {name: "Processing", class: "created"} :
                shipment.acumatica_status === "acumatica_order_created" ? {name: "Order Created", class: "staged"} :
                shipment.acumatica_status === "acumatica_shipment_created" ? {name: "Shipment Created", class: "shipped"} :
                shipment.acumatica_status === "acumatica_order_failed" ? {name: "Order Failed", class: "error"} :
                shipment.acumatica_status === "acumatica_shipment_failed" ? {name: "Shipment Failed", class: "error"} : 
                {});
        setTStatus(shipment.tracking_status === "tracking_created" ? date < cutOff ? 
                    {name: "Label Created - Late", class: "error", reship: true} : 
                    {name: "Label Created", class: "created"} :
                    shipment.tracking_status === "tracking_confirmed" ? {name: "Shipped", class: "shipped"} : 
                    shipment.tracking_status === "tracking_reshipped" ? {name: "Reshipped", class: "staged"} : 
                    {});
    }, [shipment.acumatica_status, shipment.date, shipment.tracking_status]);

    const downloadLabel = async(ship) => {
        props.setLoading(true);
        let po = props.internal_po_number;
        let tn = ship.tracking_number;
        let payload = {internal_po_number: po, tracking_number: tn};
        let urls = await orderHelpers.getPackageLabelDownloadLinks(props.company, payload, props.token);
        await fileHelpers.downloadFile(urls.ps, `${po}_${tn}_packing_slip`);
        await fileHelpers.downloadFile(urls.sl, `${po}_${tn}_shipping_label`);
        props.setLoading(false);
    }

    const reship = async(ship) => {
        if (!window.confirm(`Reship the package with tracking number ${ship.tracking_number}?`)) return;
        props.setLoading(true);
        setReshipSuccess("");
        let tn = ship.tracking_number;
        let payload = {tracking_number: tn};
        let res = await orderHelpers.reshipPackage(props.company, payload, props.token);
        setReshipSuccess(res.success ? "success" : "error");
        if (res.success) setTStatus({name:"Reshipped", class: "staged"});
        props.setLoading(false);
    }

    const removeFromPullsheet = async(ship) => {
        if (ship.tracking_number){
            if (!window.confirm(`Remove package ${ship.tracking_number} from Pullsheet #${ship.pullsheet_id}?`)) return;
        }
        else {
            if (!window.confirm(`Remove po number ${props.internal_po_number} from Pullsheet #${ship.pullsheet_id}?`)) return;
        }
        props.setLoading(true);
        await orderHelpers.removeFromPullsheet(JSON.stringify({tracking_number: ship.tracking_number, pullsheet_id: ship.pullsheet_id, internal_po_number: props.internal_po_number}), props.company, props.token);
        props.setLoading(false);
    }

    return (
        <Box sx = {{ p: "20px", width: "100%" }}>
            <List dense>
                <Grid container spacing = {1}>
                    <Grid item sm = {12} md = {12} lg = {6}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Carrier"
                                secondary = {shipment.carrier ? shipment.carrier : "N/A"}
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    minWidth: '150px',
                                }}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item sm = {12} md = {12} lg = {6}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Method"
                                secondary = {shipment.method ? shipment.method : "N/A"}
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    minWidth: '150px',
                                }}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item sm = {12} md = {12} lg = {6}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Tracking Number"
                                secondary = {
                                    shipment.tracking_number
                                        ? (() => {
                                            const trackingNumber = shipment.tracking_number;
                                            let trackingUrl;

                                            if (trackingNumber.length === 12) {
                                                trackingUrl = `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`;
                                            } else if (trackingNumber.length === 18) {
                                                trackingUrl = `https://www.ups.com/track?track=yes&trackNums=${trackingNumber}`;
                                            } else if (trackingNumber.length === 30) {
                                                trackingUrl = `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`;
                                            }

                                            return trackingUrl
                                                ? (
                                                    <a href = {trackingUrl} target = "_blank" rel = "noopener noreferrer">
                                                        {trackingNumber}
                                                    </a>
                                                )
                                                : trackingNumber;
                                        })()
                                        : "N/A"
                                }
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    minWidth: '150px',
                                }}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item sm = {12} md = {12} lg = {6}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Acumatica Shipment"
                                secondary = {shipment.acu_ship_num ? shipment.acu_ship_num : "N/A"}
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    minWidth: '150px',
                                }}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item sm = {12} md = {12} lg = {6}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Acumatica Status"
                                secondary = {<Typography variant = "body2" className = {status.class}>{status.name}</Typography>}
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    minWidth: '150px',
                                }}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item sm = {12} md = {12} lg = {6}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Truck Number"
                                secondary = {shipment.truck_number ? shipment.truck_number : "N/A"}
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    minWidth: '150px',
                                }}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item sm = {12} md = {12} lg = {6}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Tracking Status"
                                secondary = {<Typography variant = "body2" className = {tStatus.class}>{tStatus.name}</Typography>}
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    minWidth: '150px',
                                }}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item sm = {12} md = {12} lg = {6}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Warehouse"
                                secondary = {shipment.warehouse ? shipment.warehouse : "N/A"}
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    minWidth: '150px',
                                }}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item sm = {12} md = {12} lg = {6}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Pullsheet"
                                secondary = {shipment.pullsheet_id ? shipment.pullsheet_id : "N/A"}
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    minWidth: '150px',
                                }}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item sm = {12} md = {12} lg = {6}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Total Quantity"
                                secondary = {shipment.total_on_shipment ? shipment.total_on_shipment : "N/A"}
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    minWidth: '150px',
                                }}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs = {12}>
                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ListItemText
                                primary = "Reshipped?"
                                secondary = {shipment.reshipped_date ? "Yes" : "No"}
                                secondaryTypographyProps = {{
                                    sx: {
                                        color: shipment.reshipped_date ? '#64D124' : '#DD2700',
                                    }
                                }}
                                sx = {{
                                    mt: -1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    gap: 5,
                                }}
                            />
                        </ListItem>
                    </Grid>
                </Grid>
            </List>
            <Divider flexItem />
            <Box sx = {{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: "10px", mt: 2, mb: 2, flexWrap: "nowrap" }}>
                <Button
                    onClick = {() => downloadLabel(shipment)}
                    sx = {{ color: "white", whiteSpace: "nowrap", width: "155px", flexShrink: 0 }}
                    className = {`${props.warehouse} ${props.company} primary`}
                >
                    Download Label
                </Button>

                {tStatus.reship && props.status.name !== "Order Cancelled" ? (
                    <Button
                        disabled = {props.isLoading}
                        onClick = {() => reship(shipment)}
                        sx = {{ whiteSpace: "nowrap", width: "150px", flexShrink: 0 }}
                        variant = "contained"
                        className = {`${props.warehouse} ${props.company} tertiary`}
                    >
                        Reship
                    </Button>
                ) : null}

                {reshipSuccess === "success" || reshipSuccess === "error" ? (
                    <Alert severity = {reshipSuccess}>
                        {reshipSuccess === "success" ? "Package Reset" : "Failed to Reset Package"}
                    </Alert>
                ) : null}

                {shipment.pullsheet_id ? (
                    <Button
                        disabled = {props.isLoading}
                        onClick = {() => removeFromPullsheet(shipment)}
                        sx = {{ color: "error", whiteSpace: "nowrap", width: "225px", flexShrink: 0 }}
                        variant = "contained"
                    >
                        Remove From Pullsheet
                    </Button>
                ) : null}
            </Box>
            <Divider flexItem />
            <Box display = "flex" flexDirection = "column" style = {{ overflowY: "overlay", height: "45%" }}>
                <DetailsItems items = {shipment.items} />
            </Box>
        </Box>

    );
};

export default DetailsPackage;