import React, { useState } from 'react';
import Box from '@mui/material/Box';
import BulkPullsheetsForm from './BulkPullsheetsForm'
import orderHelpers from '../../helpers/orders';
import acumaticaHelpers from '../../helpers/acumatica';
import {jsPDF} from "jspdf";
import 'jspdf-autotable';

function BulkPullsheets(props) {

    const company = props.company;
    const token = props.token;

    const [acumaticaOrderNum, setAcumaticaOrderNum] = useState('');

    const handleChange = (event) => {
        let func = `set${event.target.name[0].toUpperCase() + event.target.name.substring(1)}`
        eval(func)(event.target.value)
    }

    const formatAcuOrderNum = (acuOrderNum) => {
        while (acuOrderNum.length < 7) {
            acuOrderNum = '0' + acuOrderNum;
        }
        return acuOrderNum;
    }

    const formatOrders = async(orders) => {
        let pos = {};
        for(let order of orders) {
            let po = order.internal_po_number;
            if(!(po in pos)) {
                pos[po] = {
                    acu_order_num: order.acu_order_num,
                    address1: order.address1,
                    address2: order.address2,
                    city: order.city,
                    external_po_number: order.external_po_number,
                    internal_po_number: po,
                    name: order.name,
                    order_date: order.order_date,
                    order_status: order.order_status,
                    state: order.state,
                    upload_date: order.upload_date,
                    vendor_status: order.vendor_status,
                    vendor_name: order.vendor_name,
                    zip_code: order.zip_code,
                    shipments: {},
                    items: []
                }
            }
            let tn = order.pro_number;
            if(!(order.pro_number in pos[po].shipments)) {
                pos[po].shipments[tn] = {
                    acu_ship_num: order.acu_ship_num,
                    acumatica_status: order.acumatica_status,
                    carrier: order.carrier,
                    method: order.method,
                    date: order.upload_date,
                    truck_number: order.truck_number,
                    pro_number: tn,
                    shipment_status: order.shipment_status,
                    tote_number: order.tote_number,
                    vendor_status: order.vendor_status,
                    warehouse: order.warehouse,
                    total_on_shipment: 0,
                    pullsheet_id: order.pullsheet_id,
                    items: {}
                }
            }
            let sku = order.internal_sku;
            if(!(sku in pos[po].shipments[tn].items)) {
                pos[po].shipments[tn].items[sku] = {
                    external_sku: order.external_sku,
                    root_sku: order.root_sku,
                    internal_price: order.internal_price,
                    external_price: order.external_price,
                    size: order.size,
                    pa_location: order.pa_location,
                    internal_sku: sku,
                    box: order.default_box,
                    root_sku_quantity: '',
                    quantity: 0
                }
            }
            pos[po].items.push({order_id: order.order_id, root_sku: order.root_sku, root_sku_quantity: '', internal_sku: sku, description: order.description, size: order.size, box: order.default_box, quantity: order.quantity, pa_location: order.pa_location,})
            pos[po].shipments[tn].items[sku].quantity += order.quantity;
            pos[po].shipments[tn].total_on_shipment += order.quantity
        }
        let out = [];
        let id = 1;
        for(let po in pos) {
            let shipments = [];
            for(let tn in pos[po].shipments) {
                let items = [];
                for(let sku in pos[po].shipments[tn].items) {
                    items.push(pos[po].shipments[tn].items[sku]);
                }
                pos[po].shipments[tn].items = items;
                shipments.push(pos[po].shipments[tn]);
            }
            pos[po].shipments = shipments;
            pos[po].id = id;
            out.push(pos[po]);
            id++;
        }
        return out;
    }

    const formatAcumaticaOrder = (orders) => {
        let pos = {};
        for(let order of orders) {
            let po = order.CustomerOrderNbr.value;

            if(!(po in pos)) {
                pos[po] = {
                    acu_order_num: order.OrderNbr.value,
                    internal_po_number: po,
                    order_date: order.Date.value,
                    vendor_name: order.CustomerName.value,
                    shipments: {},
                    items: []
                }
            }
            let sku = order.InventoryID.value;
            if(!(sku in pos[po].items)) {
                pos[po].items.push({
                    internal_price: order.UnitPrice.value,
                    internal_sku: sku,
                    root_sku: order.RootSku.value,
                    size: order.Size.value,
                    description: order.Description_3.value,
                    pa_location: order.PAPullLocation.value,
                    quantity: order.Quantity.value,
                    root_sku_quantity: order.RootSKUQTY.value,
                    box: order.AmazonDirectBox.value
                });
            }
        }
        let out = [];
        let id = 1;
        for (let po in pos) {
            pos[po].id = id;
            out.push(pos[po]);
            id++;
        }
        return out;
    }

    const sortOrder = async(order) => {
        const items = order.items;
        const formattedItems = {};
        let total_quantity = 0;
        for (let item of items) {
            if (!(item.internal_sku in formattedItems)) {
                formattedItems[item.internal_sku] = {
                    root_sku: item.root_sku,
                    root_sku_quantity: item.root_sku_quantity,
                    internal_sku: item.internal_sku, 
                    description: item.description, 
                    size: item.size,
                    box: item.box, 
                    quantity: 0, 
                    pull_location: item.pa_location
                }
            } 
            formattedItems[item.internal_sku].quantity += item.quantity;
            total_quantity += item.quantity;
        }
        const sortedItems = Object.values(formattedItems).sort((a, b) => {
            if (!a.pull_location && b.pull_location) return 1;
            if (a.pull_location && !b.pull_location) return -1;
            if (!a.pull_location && !b.pull_location) return 0;
        
            if (a.pull_location < b.pull_location) return -1;
            if (a.pull_location > b.pull_location) return 1;
        
            return 0;
        });
        let pdfItems = [];
        for(let sku in sortedItems) {
            let item = sortedItems[sku];
            pdfItems.push([item?.root_sku, item?.root_sku_quantity, item?.internal_sku, item?.description, item?.size, item?.box, item.quantity, item?.pull_location])
        }
        return {pdfItems: pdfItems, total_quantity: total_quantity}
    }

    const getOrder = async() => {
        let params = {
            acu_order_num: acumaticaOrderNum !== '' ? formatAcuOrderNum(acumaticaOrderNum) : null,   
        }
        console.log(params);
        try {
            const res = await orderHelpers.getBulkOrders(props.company + "_bulk", params, props.token);
            const formatted_order = await formatOrders(res);
            return formatted_order;
        } catch(err) {
            console.log(err);
        }
    }

    const getAcumaticaOrder = async() => {
        try {
            const acu_order = await acumaticaHelpers.getAcumaticaOrderDetail(formatAcuOrderNum(acumaticaOrderNum), company, token);
            const formatted_order = formatAcumaticaOrder(acu_order);
            return formatted_order;
        } catch(err) {
            console.log(err);
        }
    }

    const createBulkPullsheet = async() => {
        props.setLoading(true);
        try {
            if (acumaticaOrderNum === '') {
                return props.toast.error("Enter an order number!")
            } else {
                const res = await getOrder();
                let order = res[0];
                let sorted_order;
                if (order) {
                    sorted_order = await sortOrder(order);
                } else {
                    const res = await getAcumaticaOrder();
                    if (res.length) {
                        order = res[0];
                        sorted_order = await sortOrder(order);
                    } else {
                        return props.toast.error("Could not find order number!")
                    }
                } 
                const JsBarcode = require ('jsbarcode');
                let barcode = document.createElement("img");
                JsBarcode(barcode, order.acu_order_num);
                const sorted_items = sorted_order.pdfItems;
                const total_quantity = sorted_order.total_quantity;
                await makePullsheetPDF(order.acu_order_num, barcode, order.internal_po_number, order.vendor_name, sorted_items, total_quantity);
            }
        } catch(err) {
            console.log(err);
        } finally {
            props.setLoading(false);
        }
    }

    const makePullsheetPDF = async(acu_order_num, barcode, internal_po_number, customer, items, total) => {
        let doc = new jsPDF();

        doc.setFontSize(10);
        let now = new Date();
        let date = now.toISOString().slice(0,10)
        doc.text(180, 15, date);

        doc.addImage(barcode.src, 150, 20);

        const label_x = 10;
        const value_x = 50;
        doc.text(label_x, 15, "Acumatica Order #:");
        doc.text(value_x, 15, acu_order_num);
        doc.text(label_x, 20, "PO #:");
        doc.text(value_x, 20, internal_po_number);
        doc.text(label_x, 25, "Customer:");
        doc.text(value_x, 25, customer);

        doc.setFontSize(16);
        doc.text(15, 60, "Total Pieces: " + String(total));
        doc.autoTable({
            head: [["Root SKU", "Root SKU Qty", "SKU", "Description", "Size", "Box", "Qty", "Pull Location"]],
            body: items,
            theme: "striped",
            columnStyles: {
                3: {cellWidth: 'auto'}
            },
            startY: 62,
            margin: {top: 15, bottom: 15, left: 15, right: 15},
            headStyles: {
                fillColor: "#187947",
                textColor: "white",
                halign: "center",
                valign: "middle"
            },
            didParseCell: function (data) {
                data.cell.styles.fontSize = 8.5;
            }
        });

        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);

        const printWindow = window.open(pdfUrl);
        if (printWindow) {
            printWindow.onload = () => {
                printWindow.focus();
                printWindow.print();
            };
        }
    };

    return(
        <Box className = {props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
            <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{paddingLeft: 2}}>
                <h1>Bulk Pull Sheet</h1>
                <BulkPullsheetsForm
                    isMobile = {props.isMobile}
                    acumaticaOrderNum = {acumaticaOrderNum}
                    handleChange = {handleChange}
                    createBulkPullsheet = {createBulkPullsheet}
                />
            </Box>
        </Box>
    )
}

export default BulkPullsheets;